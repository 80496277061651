import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UnterschriftService {

  constructor(private http: HttpClient) {
  }

  public getAnzahlDokumente(kundeId: string): Observable<number> {
    const params = new HttpParams().set('kundeId', kundeId);
    return this.http.get<number>(`/unterschrift/api/dokumente/anzahl`, {params});
  }
}
