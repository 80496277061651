<mat-toolbar class="header mat-elevation-z6 z-2 fixed top-0 left-0 text-white" color="primary">
  <div class="mr-1 text-size" style="width: inherit">{{ title }}</div>
  <ng-container *ngIf="{value: (anzahlDokumenteForKunde$ | async)} as anzahl">
    <button class="icon-button-20 light-hover"
            mat-icon-button
            *ngIf="anzahl.value !== null"
            [matBadge]="anzahl.value"
            [matBadgeHidden]="anzahl.value <= 0"
            matBadgeColor="warn"
            matTooltip="Zur Unterschriften-Mappe"
            matBadgeSize="medium"
            matBadgeOverlap="false"
            color="primary" type="button"
            (click)="openUnterschriftMappe()">
      <mat-icon svgIcon="signature_icon"></mat-icon>
    </button>
  </ng-container>
  <button mat-icon-button type="button" class="light-hover" (click)="logoutService.logOut()">
    <mat-icon fontSet="material-icons-outlined">logout</mat-icon>
  </button>
</mat-toolbar>
<div class="header-spacer"></div>
<main class="flex flex-column">
  <ng-content></ng-content>
</main>
