import { Component, Input } from '@angular/core';
import { Person } from '@taures/angular-commons';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-konzept-subheader',
  templateUrl: './konzept-subheader.component.html',
  styleUrls: ['./konzept-subheader.component.scss'],
  host: { class: 'w-full' }
})
export class KonzeptSubheaderComponent  {
  environment = environment;

  @Input()
  customer?: Person;
  @Input()
  hideButtons: boolean;

  constructor(readonly router: Router) { }

  back () {
    this.router.navigate([this.isBusiness() ? 'businesses' : 'customers']);
  }

  isBusiness () {
    return this.customer?.anrede === 'Firma';
  }
}
