import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment';
import {JwksValidationHandler} from 'angular-oauth2-oidc-jwks';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(readonly oauthService: OAuthService) {
  }

  init(): Promise<void> {
    this.oauthService.configure({
      issuer: environment.oauth.issuer,
      redirectUri: window.location.origin + window.location.pathname,
      clientId: environment.oauth.clientId,
      scope: environment.oauth.scope,
      responseType: environment.oauth.responseType,
      disablePKCE: environment.oauth.disablePKCE
    });
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    return new Promise((resolveFn, rejectFn) => {
      // continue initializing app (provoking a token_received event) or redirect to login-page
      this.oauthService.loadDiscoveryDocumentAndLogin({state: window.location.search}).then(isLoggedIn => {
        if (isLoggedIn) {
          if (this.oauthService.state) {
            window.location.search = decodeURIComponent(this.oauthService.state);
          }
          this.oauthService.setupAutomaticSilentRefresh();
          resolveFn();
        } else {
          rejectFn();
        }
      });
    });
  }
}

export function AppInitServiceFactory(service: AppInitService): () => Promise<any> {
  return () => service.init();
}
