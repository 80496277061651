import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {COUNTRY_CODES} from "../../services/country-codes";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {
  Address,
  AgentService,
  Contact,
  Person,
  PersonService,
  ToastService,
  UserService
} from "@taures/angular-commons";
import {HttpErrorResponse} from "@angular/common/http";
import {CrmService} from "../../services/crm.service";
import {MatDialogRef} from "@angular/material/dialog";
import {City, PlzService} from "../../services/plz-service";
import {UniqueEmployerValidator} from "./unique-employer.validator";

@Component({
  selector: 'app-create-commercial-customer-dialog',
  templateUrl: './create-commercial-customer-dialog.component.html',
  styleUrls: ['./create-commercial-customer-dialog.component.scss']
})

export class CreateCommercialCustomerDialogComponent implements OnInit, OnDestroy {
  plzValidator = Validators.pattern(/^[0-9]{5}$/);
  form: UntypedFormGroup;
  includeConsultantFilter = {};
  betreuerFilter = {only34d: true, excludeInactive: true};
  loading = false;
  cityList: City[] = [];
  sortedCountries: { code: string, name: string }[] = COUNTRY_CODES;
  private destroy = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private personService: PersonService,
    private userService: UserService,
    private agentService: AgentService,
    private crmService: CrmService,
    private dialogRef: MatDialogRef<CreateCommercialCustomerDialogComponent>,
    private toastService: ToastService,
    private plzService: PlzService,
    readonly uniqueEmployerValidator: UniqueEmployerValidator
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(50)], [CreateCommercialCustomerDialogComponent.getUniqueEmployerValidation(this.uniqueEmployerValidator).bind(this)]],
      street: [null, [Validators.required, Validators.maxLength(255)]],
      number: [null, [Validators.required]],
      co: ['', [Validators.maxLength(255)]],
      postalCode: [null, [Validators.required, this.plzValidator]],
      city: [null, [Validators.required, Validators.maxLength(255)]],
      country: [COUNTRY_CODES[0].code, [Validators.required]],
      phone: [null, [Validators.pattern(/^([+0])\d+[\d \/\-.]*\d+$/)]],
      mail: [null, [Validators.email]],
      vp: null,
      betreuer: null,
      ansprechpartner: [null, Validators.maxLength(255)]
    });
    this.userService.getCurrentUser().pipe(takeUntil(this.destroy)).subscribe(currentUser => {
      this.form.get('vp').setValue(currentUser.id);
    });
    this.agentService.getCurrentSupervisor().pipe(takeUntil(this.destroy)).subscribe(supervisor => {
      this.form.get('betreuer').setValue(supervisor.id);
    });
  }

  static getUniqueEmployerValidation(uniqueEmployerValidator: UniqueEmployerValidator) {
    return (control: AbstractControl) => {
      return uniqueEmployerValidator
        .checkIfDuplicateExists(control.value);
    };
  }

  ngOnInit(): void {
    this.form.get('postalCode')?.valueChanges.pipe(
      takeUntil(this.destroy)
    ).subscribe(change => {
      if (change) {
        const plz = this.form.get('postalCode');
        const country = this.form.get('country');
        if (plz?.valid) {
          const list = this.plzService.getCities(plz.value, country?.value);
          list.forEach(value => {
            this.cityList = value;
          });
        } else {
          this.cityList = [];
        }
      }
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const newEmployer = {
        anrede: 'Firma',
        firstName: '',
        lastName: this.form.get('name')?.value,
        addresses: [this.generateAddress()],
        kommunikationsdaten: this.generateContacts(),
        ansprechpartner: this.form.get('ansprechpartner')?.value,
      };
      this.crmService.createCustomer(newEmployer)
        .pipe(takeUntil(this.destroy))
        .subscribe(person => this.dialogRef.close(person), error => {
          if (error instanceof HttpErrorResponse && error.status === 409) {
            const customer = error.error as Person;
            if (!customer.kunde) {
              this.toastService.queueToastMessage({
                message: 'Dieser Kunde existiert schon im System.',
                notificationType: "error"
              });
              this.dialogRef.close();
              return;
            }
            this.personService.getPerson(customer.kunde.betreuer)
              .pipe(takeUntil(this.destroy))
              .subscribe(
                consultant => {
                  if (consultant) {
                    this.toastService.queueToastMessage({
                      message: `Dieser Kunde existiert schon im System und wird
                        von ${consultant.vorname} ${consultant.nachname} betreut.
                        Um Zugriff auf diesen Kunden zu erhalten,
                        lasse dich bitte als Stellvertreter eintragen.`,
                      notificationType: 'error'
                    });
                    this.dialogRef.close();
                  }
                });
          }
        });
    }
  }

  generateAddress(): Address {
    return {
      land: this.form.get('country')?.value,
      strasse: this.form.get('street')?.value,
      nummer: this.form.get('number')?.value,
      adresseco: this.form.get('co')?.value,
      plz: this.form.get('postalCode')?.value,
      stadt: this.form.get('city')?.value
    };
  }

  generateContacts(): Contact[] {
    const mail: Contact = {
      typ: 1,
      vorrang: true,
      eintrag: this.form.get('mail')?.value
    };
    const phone: Contact = {
      typ: 7,
      vorrang: true,
      eintrag: this.form.get('phone')?.value
    };
    return [mail, phone];
  }

  getCities(): City[] {
    return this.cityList;
  }

  updateCountry(countryCode: string, event: any): void {
    if (event.isUserInput) {
      if (countryCode == COUNTRY_CODES[0].code) {
        this.form.get('postalCode')?.addValidators(this.plzValidator);
      } else {
        this.form.get('postalCode')?.removeValidators(this.plzValidator);
      }
      this.form.get('postalCode')?.updateValueAndValidity();
    }
  }

  hasNumberInStreet(): boolean {
    const value: string = this.form.get('street')?.value;
    if (value && !this.form.get('street')?.disabled) {
      return value.search(/\d/) != -1;
    }
    return false;
  }

  public hasDuplicateEmployer(): boolean {
    return this.form.get('name')?.hasError('uniqueEmployer') ?? false;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
