import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {LogoutService} from '@taures/angular-commons';

@Injectable({
  providedIn: 'root'
})
export class OauthLogoutService implements LogoutService {

  constructor(readonly oAuthService: OAuthService) {
  }

  logOut(): void {
    this.oAuthService.logOut();
  }
}
