<div class="flex justify-content-between align-items-center">
  <h2 mat-dialog-title class="dialog-title-size">Gewerbekunden anlegen</h2>
  <button mat-icon-button mat-dialog-close class="leading-none pr-2" [autofocus]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="py-0">
  <div *ngIf="hasDuplicateEmployer()" class="mb-4">
    <tr-message message="Ein Arbeitgeber mit dem gleichen Namen existiert bereits im System." type="error"></tr-message>
  </div>
  <form [formGroup]="form" (submit)="onSubmit()" novalidate>
    <div class="flex flex-column">
      <div class="flex gap-3">
        <tr-user-autocomplete placeholder="Vertriebspartner"
                              [mode]="'agent'"
                              [additionalFilter]="includeConsultantFilter"
                              formControlName="vp"
                              class="w-6">
        </tr-user-autocomplete>
        <tr-user-autocomplete placeholder="Betreuer"
                              [mode]="'agent'"
                              [additionalFilter]="betreuerFilter"
                              formControlName="betreuer"
                              class="w-6">
        </tr-user-autocomplete>
      </div>
      <mat-form-field title="Name" id="name">
        <mat-label>Firmenname</mat-label>
        <input matInput maxlength="50" formControlName="name" required>
      </mat-form-field>
      <mat-form-field title="Ansprechpartner" id="ansprechpartner">
        <mat-label>Ansprechpartner</mat-label>
        <input matInput maxlength="50" formControlName="ansprechpartner">
      </mat-form-field>
      <div class="flex gap-3">
        <mat-form-field title="Straße" class="w-8">
          <mat-label>Straße</mat-label>
          <input maxlength="150" matInput formControlName="street" required>
          <mat-hint *ngIf="hasNumberInStreet()" class="text-red-500">Bitte prüfen: Zahlen im Straßennamen</mat-hint>
        </mat-form-field>
        <mat-form-field title="Hausnummer" class="w-4">
          <mat-label>Hausnummer</mat-label>
          <input matInput maxlength="20" formControlName="number" required>
        </mat-form-field>
      </div>
      <mat-form-field title="c/o">
        <mat-label>c/o</mat-label>
        <input matInput maxlength="100" formControlName="co">
      </mat-form-field>
      <div class="flex gap-3">
        <mat-form-field title="Postleitzahl" class="w-4">
          <mat-label>Postleitzahl</mat-label>
          <input matInput maxlength="30" formControlName="postalCode" required>
        </mat-form-field>
        <mat-form-field title="Ort" class="w-8">
          <mat-label>Ort</mat-label>
          <input matInput maxlength="100" formControlName="city" [matAutocomplete]="autocomplete" required>
          <mat-autocomplete #autocomplete="matAutocomplete" autoActiveFirstOption="true">
            <mat-option *ngFor="let city of getCities()" [value]="city">
              <span>{{city}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <mat-form-field title="Land" class="block">
        <mat-label>Land</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let country of sortedCountries"
                      (onSelectionChange)="updateCountry($event.source.value, $event)" [value]="country.code">
            {{country.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="flex gap-3">
        <mat-form-field title="Telefonnummer" class="w-6">
          <mat-label>Telefonnummer</mat-label>
          <input matInput maxlength="24" formControlName="phone">
        </mat-form-field>
        <mat-form-field title="E-Mail-Adresse" class="w-6">
          <mat-label>E-Mail-Adresse</mat-label>
          <input matInput maxlength="100" formControlName="mail">
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="flex pt-4">
  <button mat-stroked-button type="button" mat-dialog-close [disabled]="loading">Abbrechen</button>
  <button mat-flat-button type="submit" color="primary" [disabled]="loading || !form.valid" (click)="onSubmit()">
    Anlegen
  </button>
</mat-dialog-actions>
