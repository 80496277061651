import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {KeycloakTokenService, Person} from '@taures/angular-commons';
import {MatDialog} from '@angular/material/dialog';
import {CreateCommercialCustomerDialogComponent} from "../create-commercial-customer-dialog/create-commercial-customer-dialog.component";
import {Router} from '@angular/router';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {CreateCustomerRedirectDialogComponent} from "../create-customer-redirect-dialog/create-customer-redirect-dialog.component";



@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateCustomerComponent {
  loading = false;

  constructor(readonly dialog: MatDialog,
              readonly changeDetector: ChangeDetectorRef,
              private router: Router,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "gewerbe_plus_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/gewerbe_plus_icon.svg")
    );

    router.events.subscribe(() => {
      this.changeDetector.markForCheck()
    })
  }

  createPerson(): void {
    this.dialog.open(CreateCustomerRedirectDialogComponent, {
      panelClass: '',
      width: '600px'
    });
  }

  createCommercialCustomer(): void {
    this.dialog.open(CreateCommercialCustomerDialogComponent, {
      width: '612px'
    }).afterClosed()
      .subscribe(result => {
        if (result && result.id) {
          this.router.navigate(['businesses', result.personManagementId]);
        }
      });
  }

  onBusinessSide(): boolean {
    return this.router.url.startsWith('/businesses');
  }
}
