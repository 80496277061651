import {AfterViewInit, Component, ElementRef, Injectable, OnDestroy, ViewChild} from '@angular/core';
import {FormGroupDirective, UntypedFormControl, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {Observable, ObservableInput, Subject} from 'rxjs';

import {catchError, filter, map, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {Person, PersonService, ToastService} from '@taures/angular-commons';
import {PerMaPerson} from '../../services/person-management.service';
import {HttpErrorResponse} from '@angular/common/http';
import {CrmService} from "../../services/crm.service";
import {ErrorStateMatcher} from '@angular/material/core';


/** The default error matcher only validates control if dirty, touched, or submitted. */
@Injectable()
export class PermanentValidationErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | null): boolean {
    return control && control.invalid && (form.submitted || (control.value?.length > 0) || (control.value?.length === 0 && control.dirty))
  }
}

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.css'],
  providers: [
    {provide: ErrorStateMatcher, useClass: PermanentValidationErrorStateMatcher},
  ]
})
export class CustomerSearchComponent implements OnDestroy, AfterViewInit {
  customers: Observable<PerMaPerson[]>;
  name = new UntypedFormControl('', [this.NoResultsValidator.bind(this), Validators.minLength(2), Validators.required]);
  hasSearched = false;
  emptyResult = false;
  private destroy = new Subject<void>();
  @ViewChild('inputFocusElement') inputElement: ElementRef;

  private NoResultsValidator(): ValidationErrors {
    if (this.emptyResult && !this.name.dirty) return {noResults: 'No search results'};
    return null;
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private personService: PersonService,
              private toastService: ToastService,
              private crmService: CrmService,
              private titleService: Title) {
    this.titleService.setTitle('Konzept erstellen - TauRes');
    this.customers = this.route.queryParams
      .pipe(
        map(params => params.name),
        tap(name => {
          this.name.reset(name, {emitEvent: false});
          this.hasSearched = false;
          this.emptyResult = false;
        }),
        filter(name => !!name),
        mergeMap(name => this.crmService.loadPersons(name)),
        map(persons => {
          return persons.map(person => CustomerSearchComponent.isPerMaPerson(person) ? person : CustomerSearchComponent.asPerMaPerson(person));
        }),
        map(persons => persons.sort((a, b) => {
          return (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName) || a.birthDate.getTime() - b.birthDate.getTime();
        })),
        tap(persons => {
          this.hasSearched = true;
          this.emptyResult = persons.length === 0;
          this.name.updateValueAndValidity();
        }),
        catchError((): ObservableInput<PerMaPerson[]> => {
          this.toastService.queueToastMessage(
            {
              message: 'Kundendaten können nicht geladen werden.',
              notificationType: 'error'
            });
          return [];
        }));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.focus();
  }

  onSubmit(): void {
    if (this.name.valid) {
      this.emptyResult = false
      this.router.navigate(['/customers'], {
        queryParams: {
          name: this.name.value
        }
      });
    }
  }

  onSelect(customer: PerMaPerson): void {
    this.personService.loadPerson(customer.personManagementId)
      .pipe(takeUntil(this.destroy))
      .subscribe(person => {
        this.router.navigate(['customers', person.personManagementId]);
      }, (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.toastService.queueToastMessage({
            message: 'Kunde nicht gefunden.',
            notificationType: 'error'
          });
        }
      });
  }

  static isPerMaPerson(person: Person | PerMaPerson): person is PerMaPerson {
    return (person as PerMaPerson).lastName !== undefined;
  }

  static asPerMaPerson(person: Person): PerMaPerson {
    return {
      firstName: person.vorname,
      lastName: person.nachname,
      birthDate: person.geburtsdatum,
      personManagementId: person.personManagementId
    }
  }
}
