<div class="flex align-items-center justify-content-between pr-2">
  <h2 mat-dialog-title class="font-normal">Person anlegen</h2>
  <button mat-icon-button mat-dialog-close [autofocus]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <p class="mb-2 dialog-subtitle-size"><b>Natürliche Person anlegen</b></p>
  <p class="mb-3 mt-0">Die Anlage von natürlichen Personen erfolgt über den Potential Planer.</p>
  <div class="text-center mb-3">
    <a mat-raised-button href="{{potentialPlanerUrl}}" class="w-4 font-medium">Zum Potential Planer</a>
  </div>
  <p class="mb-2 dialog-subtitle-size"><b>Juristische Person anlegen</b></p>
  <p class="mb-3 mt-0">Zur Anlage einer juristischen Person steht die Gewerbeplattform zur Verfügung.</p>
  <div class="text-center">
    <button mat-raised-button (click)="toBusinesses()" mat-dialog-close class="w-4 font-medium">Zur Gewerbeplattform</button>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close class="font-medium">OK</button>
</mat-dialog-actions>
