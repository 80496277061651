<mat-toolbar class="sub-header mat-elevation-z6 z-1 bg-white flex overflow-hidden justify-content-end">
  <div *ngIf="customer && isBusiness()" class="kunde-text-size font-normal mr-2">{{[customer.vorname, customer.nachname].join(' ').trim()}}, {{customer.anrede}}</div>
  <div *ngIf="customer && !isBusiness()" class="kunde-text-size font-normal mr-2">{{customer.nachname + ', ' + customer.vorname}} {{customer.geburtsdatum | date: 'dd.MM.yyyy'}}</div>
  <a *ngIf="!hideButtons && customer" mat-icon-button color="primary" type="button" matTooltip="Stammdaten CRM" class="sub-header-button" [href]="environment.crmUrl + '/t-it-s/redirect?typ=kunde&id=' + customer.id" target="_blank">
    <mat-icon class="icon-color" fontSet="material-icons-outlined">folder</mat-icon>
  </a>
  <button *ngIf="!hideButtons && customer" mat-icon-button type="button" matTooltip="Kunden wechseln" class="sub-header-button icon-color" (click)="back()">
    <mat-icon fontSet="material-icons-outlined">person_search</mat-icon>
  </button>
  <app-create-customer *ngIf="!hideButtons && !customer"></app-create-customer>
</mat-toolbar>
